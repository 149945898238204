import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDate } from "../../lib/util";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import {
  atom__confirmModalInfo,
  atom__loading,
  atom__postDetailModalInfo,
  atom__postPhoneModalInfo,
  atom__postRejectModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { api_userAccept } from "../../api/user";
import { USER_LEVEL_TO_LABEL } from "../../lib/types/user.type";
import Pagination from "../../components/Table/Pagination";
import { api_postAccept, api_postList } from "../../api/post";
import { EPostStatus, TPost } from "../../lib/types/post.type";

const PostPendingPage = () => {
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setPostRejectModalInfo = useSetRecoilState(
    atom__postRejectModalInfo
  );
  const r__setPostDetailModalInfo = useSetRecoilState(
    atom__postDetailModalInfo
  );
  const r__setPostPhoneModalInfo = useSetRecoilState(atom__postPhoneModalInfo);
  const r__setLoading = useSetRecoilState(atom__loading);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TPost[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({
      page: 1,
      needTotalCount: "y",
    });
  }, []);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
    userKind?: string;
  }) => {
    api_postList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
      status: EPostStatus.PENDING,
      withTradeIng: "y",
      withTradeDone: "y",
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  const accept = async (id: string) => {
    const res = await api_postAccept(id);
    if (!res) return;

    r__setSuccessModalInfo({ desc: ["매물이 성공적으로 등록되었습니다."] });

    setCurrentPage(1);
    _fetch({ page: 1, needTotalCount: "y" });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>매물 등록 요청</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["아파트명", "중개사무소", "등록인"]}
          />
        </div>
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="아파트명" sort="none" width={200} />
            <Th text="동 / 호수" sort="none" width={150} />
            <Th text="중개사무소" sort="none" width={200} />
            <Th text="등록인" sort="none" />
            <Th text="전화번호" sort="none" width={150} />
            <Th text="등록일" sort="none" width={150} />
            <Th text="관리" sort="none" />
          </thead>
          <tbody>
            {list.map((post) => {
              return (
                <tr key={post.id}>
                  <td className="small">{post.apart.kaptName}</td>
                  <td>
                    {post.dong}동 / {post.ho}호
                  </td>
                  <td className="small">{post?.office?.name || "직접등록"}</td>
                  <td>
                    {post.creatorUser ? (
                      <>
                        {post.creatorUser.name}
                        <br />(
                        {USER_LEVEL_TO_LABEL[post.creatorUser.level] || "-"})
                      </>
                    ) : (
                      post.ownerName
                    )}
                  </td>
                  <td>
                    {" "}
                    <SmallButton
                      text="조회"
                      color="beige"
                      width={64}
                      onClick={() => {
                        r__setPostPhoneModalInfo({
                          creatorPhone: post.creatorUser?.phone || undefined,
                          ownerPhone: post.ownerPhone || undefined,
                          customerPhone: post.customerPhone || undefined,
                        });
                      }}
                    />
                  </td>
                  <td className="small">
                    {printFormattedDate(new Date(post.createdAt))}
                  </td>

                  <td>
                    <div
                      className="flex-row-center-center"
                      style={{ gap: "10px" }}
                    >
                      <SmallButton
                        text="상세"
                        color="beige"
                        width={64}
                        onClick={() => {
                          r__setPostDetailModalInfo({ postId: post.id });
                        }}
                      />

                      <SmallButton
                        text="등록"
                        color="yellow-dark"
                        width={64}
                        onClick={() => {
                          r__setLoading(true);
                          accept(post.id).finally(() => r__setLoading(false));
                        }}
                      />

                      <SmallButton
                        text="반려"
                        color="tomato"
                        width={64}
                        onClick={() => {
                          r__setPostRejectModalInfo({
                            postId: post.id,
                            onReject: () => {
                              setCurrentPage(1);
                              _fetch({ page: 1, needTotalCount: "y" });
                            },
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default PostPendingPage;
