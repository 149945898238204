import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__bannerModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useRef, useState } from "react";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import { regNumber } from "../../lib/validator";
import iconImage from "../../assets/image.png";
import {
  api_bannerCreate,
  api_bannerDetail,
  api_bannerUpdate,
} from "../../api/site";
import { api_fileUploadCommit, api_fileUploadPrepare } from "../../api/file";
import { printFormattedDate } from "../../lib/util";

const ModalBanner = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(atom__bannerModalInfo);
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bannerTarget, setBannerTarget] = useState("");
  const [clientOrg, setClientOrg] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientPhone1, setClientPhone1] = useState("");
  const [clientPhone2, setClientPhone2] = useState("");
  const [clientPhone3, setClientPhone3] = useState("");
  const [image, setImage] = useState<{
    url: string;
    file?: File;
  } | null>(null);

  const [canNext, setCanNext] = useState(false);

  const refFileInput = useRef<HTMLInputElement>(null);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  // 닫혔을 때 인풋 초기화
  useEffect(() => {
    if (r__modalInfo) {
      if (r__modalInfo.bannerId) _fetch();
      return;
    }

    setStartDate("");
    setEndDate("");
    setBannerTarget("");
    setClientOrg("");
    setClientName("");
    setClientPhone1("");
    setClientPhone2("");
    setClientPhone3("");
    setImage(null);
  }, [r__modalInfo]);

  useEffect(() => {
    setCanNext(
      !!startDate.length &&
        !!endDate.length &&
        !!bannerTarget.length &&
        !!clientOrg.length &&
        !!clientName.length &&
        !!clientPhone1.length &&
        !!clientPhone2.length &&
        !!clientPhone3.length &&
        !!image?.url
    );
  }, [
    startDate,
    endDate,
    bannerTarget,
    clientOrg,
    clientName,
    clientPhone1,
    clientPhone2,
    clientPhone3,
    image,
  ]);

  const _fetch = () => {
    if (!r__modalInfo?.bannerId) return;
    api_bannerDetail(r__modalInfo.bannerId).then((res) => {
      if (!res) return;
      const strStartDate = printFormattedDate(new Date(res.banner.startDate), {
        seperator: "-",
      });
      const strEndDate = printFormattedDate(new Date(res.banner.endDate), {
        seperator: "-",
      });
      setStartDate(strStartDate);
      setEndDate(strEndDate);
      setBannerTarget(res.banner.target);
      setClientOrg(res.banner.clientOrg);
      setClientName(res.banner.clientName);
      const [p1, p2, p3] = res.banner.clientPhone.split("-");
      setClientPhone1(p1);
      setClientPhone2(p2);
      setClientPhone3(p3);
      setImage({ url: res.banner.url });
    });
  };

  const create = async () => {
    // 이미지 업로드
    if (!image?.file) return;

    const prepareRes = await api_fileUploadPrepare(image.file.name);
    if (!prepareRes) return;

    const { id: preparedFileId, signedUrl, url } = prepareRes.preparedFiles[0];
    // s3
    const resS3 = await fetch(
      new Request(signedUrl, {
        method: "PUT",
        body: image.file,
        headers: new Headers({
          "Content-Type": image.file.type,
        }),
      })
    );
    if (resS3.status !== 200)
      return alert("죄송합니다. 잠시 후 다시 이용해 주세요.");

    await api_fileUploadCommit(preparedFileId);

    // 배너 등록
    const res = await api_bannerCreate({
      target: bannerTarget,
      startDate,
      endDate,
      clientOrg,
      clientName,
      clientPhone: `${clientPhone1}-${clientPhone2}-${clientPhone3}`,
      url,
    });
    if (!res) return;

    r__modalInfo?.onCreate?.();
    close();

    r__setSuccessModalInfo({
      desc: ["배너가 성공적으로 등록되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };

  const edit = async () => {
    if (!r__modalInfo?.bannerId) return;
    if (!image) return;

    let imageUrl = image.url;

    // 이미지 업로드
    if (image.file) {
      const prepareRes = await api_fileUploadPrepare(image.file.name);
      if (!prepareRes) return;

      const {
        id: preparedFileId,
        signedUrl,
        url,
      } = prepareRes.preparedFiles[0];
      // s3
      const resS3 = await fetch(
        new Request(signedUrl, {
          method: "PUT",
          body: image.file,
          headers: new Headers({
            "Content-Type": image.file.type,
          }),
        })
      );
      if (resS3.status !== 200)
        return alert("죄송합니다. 잠시 후 다시 이용해 주세요.");

      await api_fileUploadCommit(preparedFileId);

      imageUrl = url;
    }

    // 배너 등록
    const res = await api_bannerUpdate(r__modalInfo.bannerId, {
      target: bannerTarget,
      startDate,
      endDate,
      clientOrg,
      clientName,
      clientPhone: `${clientPhone1}-${clientPhone2}-${clientPhone3}`,
      url: imageUrl,
    });
    if (!res) return;

    r__modalInfo?.onEdit?.();
    close();

    r__setSuccessModalInfo({
      desc: ["수정되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>{r__modalInfo?.bannerId ? "배너 수정" : "배너 등록"}</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <div style={{ position: "relative", marginBottom: "20px" }}>
        <input
          type="file"
          ref={refFileInput}
          accept={"image/png, image/jpeg, image/jpg"}
          style={{
            position: "absolute",
            top: 10,
            left: 10,
            width: "10px",
            height: "10px",
          }}
          onChange={(e) => {
            if (!e.target.files?.length) {
              setImage(null);
              return;
            }
            const f = e.target.files[0];
            const localUrl = URL.createObjectURL(f);
            setImage({ url: localUrl, file: f });
          }}
        />
        <div
          style={{
            position: "relative",
            borderRadius: "8px",
            border: "1px solid #000",
            backgroundColor: "#fff",
            overflow: "hidden",
            width: "100%",
            maxWidth: "450px",
            aspectRatio: 450 / 300,
            cursor: "pointer",
          }}
          onClick={() => refFileInput.current?.click()}
        >
          {image?.url ? (
            <img
              // src={image.url}
              src={image.url}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          ) : (
            <div
              className="flex-col-center-center"
              style={{ width: "100%", height: "100%" }}
            >
              <img src={iconImage} style={{ width: "24px", height: "24px" }} />
              <p className="modal-desc" style={{ marginTop: "24px" }}>
                클릭하여 사진 업로드
              </p>
              <p className="modal-desc">사이즈 : 450 x 300</p>
            </div>
          )}
        </div>
      </div>

      <label htmlFor="startDate">시작일</label>
      <input
        id="startDate"
        type="date"
        style={{ marginBottom: "20px" }}
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
        }}
      />
      <label htmlFor="endDate">만료일</label>
      <input
        id="endDate"
        type="date"
        style={{ marginBottom: "20px" }}
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
        }}
      />

      <label htmlFor="bannerTarget">광고대상물</label>
      <input
        id="bannerTarget"
        type="text"
        placeholder="광고대상물 입력"
        maxLength={10}
        style={{ marginBottom: "20px" }}
        value={bannerTarget}
        onChange={(e) => {
          setBannerTarget(e.target.value);
        }}
      />

      <label htmlFor="clientOrg">고객사</label>
      <input
        id="clientOrg"
        type="text"
        placeholder="고객사명 입력"
        maxLength={10}
        style={{ marginBottom: "20px" }}
        value={clientOrg}
        onChange={(e) => {
          setClientOrg(e.target.value);
        }}
      />

      <label htmlFor="clientName">광고주</label>
      <input
        id="clientName"
        type="text"
        placeholder="광고주 입력"
        maxLength={10}
        style={{ marginBottom: "20px" }}
        value={clientName}
        onChange={(e) => {
          setClientName(e.target.value);
        }}
      />

      <label htmlFor="clientPhone1">광고주 전화번호</label>
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <input
          id="clientPhone1"
          type="text"
          placeholder="010"
          maxLength={3}
          style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
          value={clientPhone1}
          onChange={(e) => {
            if (e.target.value.length && !regNumber.test(e.target.value))
              return;
            setClientPhone1(e.target.value);
          }}
        />

        <input
          id="clientPhone2"
          type="text"
          placeholder="1234"
          maxLength={4}
          style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
          value={clientPhone2}
          onChange={(e) => {
            if (e.target.value.length && !regNumber.test(e.target.value))
              return;
            setClientPhone2(e.target.value);
          }}
        />

        <input
          id="clientPhone3"
          type="text"
          placeholder="1234"
          maxLength={4}
          style={{ textAlign: "center", flex: 1, maxWidth: "100px" }}
          value={clientPhone3}
          onChange={(e) => {
            if (e.target.value.length && !regNumber.test(e.target.value))
              return;
            setClientPhone3(e.target.value);
          }}
        />
      </div>

      <BaseButton
        text={r__modalInfo?.bannerId ? "수정" : "등록"}
        onClick={r__modalInfo?.bannerId ? edit : create}
        disabled={!canNext}
      />
    </ModalBase>
  );
};

export default ModalBanner;
