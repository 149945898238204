export interface IButtonCommonProps {
  disabled?: boolean;
  text: string;
  class?: string;
  style?: React.CSSProperties;
  onClick: () => void;
}
const BaseButton = (props: IButtonCommonProps) => {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={`button-base ${props.class} ${
        props.disabled ? "button-base-disabled" : ""
      }`}
      style={props.style}
    >
      {props.text}
    </button>
  );
};

export default BaseButton;
