import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDate } from "../../lib/util";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import {
  atom__confirmModalInfo,
  atom__noticeModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import SearchInputWithoutAttr from "../../components/SearchInputWithoutAttr";
import { api_noticeDelete, api_noticeList } from "../../api/site";
import { TNotice } from "../../lib/types/site.type";
import Pagination from "../../components/Table/Pagination";

const NoticeListPage = () => {
  const r__setNoticeModalInfo = useSetRecoilState(atom__noticeModalInfo);
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TNotice[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({ page: 1, needTotalCount: "y" });
  }, []);

  const openModal = (id?: string) => {
    // 생성
    if (!id) {
      r__setNoticeModalInfo({
        noticeId: null,
        onCreate: () => _fetch({ page: currentPage, needTotalCount: "y" }),
      });
      return;
    }
    // 수정
    r__setNoticeModalInfo({
      noticeId: id,
      onEdit: () => _fetch({ page: currentPage }),
    });
  };

  const deleteNotice = async (id: string) => {
    const res = await api_noticeDelete([id]);
    if (!res) return;

    r__setConfirmModalInfo(null);
    r__setSuccessModalInfo({
      title: "삭제",
      desc: ["삭제가 완료되었습니다."],
    });

    _fetch({ page: currentPage, needTotalCount: "y" });
  };

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
  }) => {
    api_noticeList({
      from: limit * (page - 1),
      limit,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>공지사항</h2>
          <SearchInputWithoutAttr
            placeholder="제목으로 검색"
            onClickSearch={(keyword) => {
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
          />
        </div>

        <SmallButton
          text="등록"
          onClick={() => {
            openModal();
          }}
          width={100}
          color="beige"
        />
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="제목" sort="none" />
            <Th text="등록일" sort="none" width={150} />
            <Th text="관리" sort="none" />
          </thead>
          <tbody>
            {list.map((notice) => {
              return (
                <tr>
                  <td>{notice.subject}</td>
                  <td>{printFormattedDate(new Date(notice.createdAt))}</td>

                  <td>
                    <div
                      className="flex-row-center-center"
                      style={{ gap: "10px" }}
                    >
                      <SmallButton
                        text="수정"
                        color="yellow-dark"
                        width={64}
                        onClick={() => {
                          openModal(notice.id);
                        }}
                      />

                      <SmallButton
                        text="삭제"
                        color="tomato"
                        width={64}
                        onClick={() => {
                          r__setConfirmModalInfo({
                            title: "삭제",
                            desc: ["해당 공지사항을 삭제하시겠습니까?"],
                            onClickBtnLeft: () => r__setConfirmModalInfo(null),
                            onClickBtnRight: () => {
                              deleteNotice(notice.id);
                            },
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default NoticeListPage;
