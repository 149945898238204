import { useRecoilState, useRecoilValue } from "recoil";
import { atom__me } from "../lib/recoil/common.atom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api_me } from "../api/auth";

const RootPage = () => {
  const navigate = useNavigate();

  const [r__me, r__setMe] = useRecoilState(atom__me);

  useEffect(() => {
    // 로그인 여부에 따라 페이지 리다이렉션
    if (r__me) {
      return navigate(
        r__me.level === 100 ? "/account/admin" : "/user/approval"
      );
    }

    api_me()
      .then((res) => {
        if (!res?.admin) return navigate("/login");
        r__setMe(res.admin);
        return navigate(
          res.admin.level === 100 ? "/account/admin" : "/user/approval"
        );
      })
      .catch((e) => {
        console.log("??? ", e);
        navigate("/login");
      });
  }, []);
  return <div> </div>;
};

export default RootPage;
