import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDate } from "../../lib/util";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import {
  atom__confirmModalInfo,
  atom__paperViewModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { TUser } from "../../lib/types/user.type";
import {
  api_officeChangeAccept,
  api_officeChangeReject,
  api_officeChangeList,
} from "../../api/office";
import Pagination from "../../components/Table/Pagination";
import { TOfficeChange } from "../../lib/types/office.type";

const UserOfficeChangePage = () => {
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);
  const r__setPaperViewModalInfo = useSetRecoilState(atom__paperViewModalInfo);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TOfficeChange[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({
      page: 1,
      needTotalCount: "y",
    });
  }, []);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
    userKind?: string;
  }) => {
    api_officeChangeList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  const approve = async (id: string) => {
    const res = await api_officeChangeAccept(id);
    if (!res) return;

    r__setConfirmModalInfo(null);
    r__setSuccessModalInfo({
      desc: ["사무소 정보가 변경되었습니다."],
    });

    _fetch({ page: 1, needTotalCount: "y" });
  };

  const reject = async (id: string) => {
    const res = await api_officeChangeReject(id);
    if (!res) return;

    r__setConfirmModalInfo(null);
    r__setSuccessModalInfo({
      desc: ["사무소 정보 변경이 반려되었습니다."],
    });

    _fetch({ page: 1, needTotalCount: "y" });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>사무소 변경 요청</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["아이디", "이름"]}
          />
        </div>
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="요청일" sort="none" width={150} />
            <Th text="아이디" sort="none" width={200} />
            <Th text="이름" sort="none" width={100} />
            <Th text="전화번호" sort="none" />
            <Th text="기존 사무소" sort="none" width={180} />
            <Th text="변경 사무소" sort="none" width={200} />
            <Th text="관리" sort="none" />
          </thead>
          <tbody>
            {list.map((change) => {
              return (
                <tr key={change.id}>
                  <td>{printFormattedDate(new Date(change.createdAt))}</td>
                  <td>{change.user.uid}</td>
                  <td>{change.user.name}</td>
                  <td>{change.user.phone}</td>
                  <td>{change.prevOffice.name}</td>
                  <td>{change.changeOffice.name}</td>
                  <td>
                    <div
                      className="flex-row-center-center"
                      style={{ gap: "10px" }}
                    >
                      <SmallButton
                        text="문서 확인"
                        color="beige"
                        width={100}
                        onClick={() => {
                          if (!change.imageUrlOffice) return;

                          r__setPaperViewModalInfo({
                            paperUrl1: change.imageUrlBusiness,
                            paperUrl2: change.imageUrlOffice,
                            paperUrl3: change.imageUrlCert,
                          });
                        }}
                      />

                      <SmallButton
                        text="승인"
                        color="yellow-dark"
                        width={64}
                        onClick={() => {
                          r__setConfirmModalInfo({
                            title: "승인",
                            desc: ["사무소 정보가 변경됩니다."],
                            onClickBtnLeft: () => r__setConfirmModalInfo(null),
                            onClickBtnRight: () => {
                              approve(change.id);
                            },
                            theme: "info",
                          });
                        }}
                      />

                      <SmallButton
                        text="반려"
                        color="tomato"
                        width={64}
                        onClick={() => {
                          r__setConfirmModalInfo({
                            title: "반려",
                            desc: ["사무소 변경이 반려됩니다."],
                            onClickBtnLeft: () => r__setConfirmModalInfo(null),
                            onClickBtnRight: () => {
                              reject(change.id);
                            },
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default UserOfficeChangePage;
