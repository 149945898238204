import Axios from "axios";

// const BASE_URL = process.env.REACT_APP_API_URL;
// const BASE_URL = "http://localhost:3000";
const BASE_URL = "https://api.honeyaptdanji.com";

console.log("baseUrl: ", BASE_URL);
const axios = Axios.create({
  baseURL: BASE_URL,
});

axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("at")}`,
};
axios.defaults.withCredentials = true;

export default axios;
