import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDate } from "../../lib/util";
import Select from "../../components/Select";
import iconMedalGold from "../../assets/medal_gold.png";
import iconMedalSilve from "../../assets/medal_silver.png";
import iconMedalBronze from "../../assets/medal_bronze.png";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import {
  atom__alertModalInfo,
  atom__confirmModalInfo,
  atom__membershipModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import {
  api_membershipPendingList,
  api_membershipRefund,
  api_membershipSms,
} from "../../api/membership";
import {
  EUserMembershipStatus,
  TUserMembership,
} from "../../lib/types/membership.type";
import Pagination from "../../components/Table/Pagination";

const MembershipPage = () => {
  const r__setMembershipModalInfo = useSetRecoilState(
    atom__membershipModalInfo
  );
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setAlertModalInfo = useSetRecoilState(atom__alertModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TUserMembership[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({
      page: 1,
      needTotalCount: "y",
    });
  }, []);

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
    userKind?: string;
  }) => {
    api_membershipPendingList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  const sendMessage = async (membershipId: string) => {
    const res = await api_membershipSms(membershipId);
    if (!res) {
      r__setAlertModalInfo({
        title: "실패",
        desc: ["문자 발송에 실패하였습니다."],
      });
      return;
    }

    r__setSuccessModalInfo({ desc: ["문자를 성공적으로 발송하였습니다."] });
  };

  const refund = async (membershipId: string) => {
    const res = await api_membershipRefund(membershipId);
    if (!res) return;

    _fetch({ page: 1, needTotalCount: "y" });

    r__setConfirmModalInfo(null);
    r__setSuccessModalInfo({
      desc: ["멤버십 비활성화가 성공적으로 완료되었습니다."],
    });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>멤버십 신청 관리</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["이름", "아이디"]}
          />
        </div>
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="이름" sort="none" width={100} />
            <Th text="아이디" sort="none" width={180} />
            <Th text="전화번호" sort="none" width={200} />
            <Th text="요청" sort="none" />
            <Th text="기존 멤버십 기간" sort="none" />
            <Th text="관리" sort="none" />
          </thead>
          <tbody>
            {list.map((userMembership) => {
              return (
                <tr key={userMembership.id}>
                  <td>{userMembership.user.name}</td>
                  <td>{userMembership.user.uid}</td>
                  <td>{userMembership.user.phone}</td>
                  <td>
                    {userMembership.status ===
                    EUserMembershipStatus.REFUND_PENDING
                      ? "환불 ("
                      : ""}
                    {userMembership.membershipInfo.name}
                    {userMembership.status ===
                    EUserMembershipStatus.REFUND_PENDING
                      ? ")"
                      : ""}
                  </td>
                  <td className="small">
                    {userMembership.user.activeMembership?.startDate &&
                    userMembership.user.activeMembership?.endDate
                      ? `${printFormattedDate(
                          new Date(
                            userMembership.user.activeMembership.startDate
                          )
                        )} - ${printFormattedDate(
                          new Date(userMembership.user.activeMembership.endDate)
                        )}`
                      : "-"}
                  </td>
                  <td>
                    <div
                      className="flex-row-center-center"
                      style={{ gap: "10px" }}
                    >
                      <SmallButton
                        text="문자발송"
                        color="beige"
                        width={100}
                        onClick={() => {
                          sendMessage(userMembership.id);
                        }}
                        style={{ fontSize: "12px" }}
                      />

                      <SmallButton
                        text="승인"
                        color="yellow-dark"
                        width={64}
                        onClick={() => {
                          r__setMembershipModalInfo({
                            membershipId: userMembership.id,
                            onAccept: () =>
                              _fetch({ page: 1, needTotalCount: "y" }),
                          });
                        }}
                      />

                      <SmallButton
                        text="환불"
                        color="tomato"
                        width={64}
                        onClick={() => {
                          r__setConfirmModalInfo({
                            title: "환불",
                            desc: [
                              "환불 클릭 시 해당 멤버십은 정지됩니다.",
                              "환불은 직접 진행해주시고, 환불이 완료된 경우",
                              "승인버튼을 눌러 비활성화를 해주세요.",
                            ],
                            onClickBtnLeft: () => r__setConfirmModalInfo(null),
                            onClickBtnRight: () => {
                              refund(userMembership.id);
                            },
                            theme: "alert",
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default MembershipPage;
