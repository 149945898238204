import { TListQuery } from "../lib/types/common.type";
import { TLogAdmin } from "../lib/types/log.type";
import axios from "./axios";

export const api_logAdmin = async (query: TListQuery) => {
  const res = await axios.get("/log", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TLogAdmin[]; totalCount?: number };
};
