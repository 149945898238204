import { TAdminCreatePayload } from "../lib/types/admin.type";
import { TListQuery } from "../lib/types/common.type";
import axios from "./axios";

export const api_salesCreate = async (payload: TAdminCreatePayload) => {
  const res = await axios.post("/user/sales", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_salesDelete = async (ids: string[]) => {
  const res = await axios.delete("/user/sales", { data: { ids } });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_salesList = async (query: TListQuery) => {
  const res = await axios.get("/user/sales", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};
