import { useCallback } from "react";
import ModalBase from "./Base";
import { useRecoilState } from "recoil";
import { atom__successModalInfo } from "../../lib/recoil/modal.atom";
import check from "../../assets/check.png";
import BaseButton from "../Button/Base";

const ModalSuccess = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__successModalInfo
  );

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);
  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-col-center-center">
        <img
          src={check}
          style={{ width: "48px", height: "48px", marginBottom: "10px" }}
        />
        <h2 style={{ marginBottom: "20px" }}>
          {r__modalInfo?.title ?? "성공"}
        </h2>
        <div style={{ marginBottom: "40px" }}>
          {r__modalInfo?.desc.map((d, i) => (
            <p className="modal-desc" key={i}>
              {d}
            </p>
          ))}
        </div>
        <BaseButton
          onClick={r__modalInfo?.onClickBtn ?? close}
          text={r__modalInfo?.btnText ?? "확인"}
          style={{ width: "344px" }}
        />
      </div>
    </ModalBase>
  );
};

export default ModalSuccess;
