import { useCallback } from "react";
import ModalBase from "./Base";
import { useRecoilState } from "recoil";
import { atom__paperViewModalInfo } from "../../lib/recoil/modal.atom";
import BaseButton from "../Button/Base";

interface IPaperBoxProps {
  name: string;
  url: string;
  style?: React.CSSProperties;
}
const PaperBox = (props: IPaperBoxProps) => {
  return (
    <div className="flex-row-between-center" style={props.style}>
      <p className="modal-desc">{props.name}</p>
      <div
        style={{ width: "91px", height: "115px", backgroundColor: "#D9D9D9" }}
      >
        <img
          src={props.url}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(props.url, "_blank");
          }}
        />
      </div>
    </div>
  );
};

const ModalPaperView = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__paperViewModalInfo
  );

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>제출 문서 확인</h2>
      </div>

      {r__modalInfo?.paperUrl1 && (
        <PaperBox
          name="사업자등록증"
          url={r__modalInfo.paperUrl1}
          style={{ marginBottom: "20px" }}
        />
      )}
      {r__modalInfo?.paperUrl2 && (
        <PaperBox
          name="사무소등록증"
          url={r__modalInfo.paperUrl2}
          style={{ marginBottom: "20px" }}
        />
      )}
      {r__modalInfo?.paperUrl3 && (
        <PaperBox
          name="공인중개사 자격증"
          url={r__modalInfo.paperUrl3}
          style={{ marginBottom: "20px" }}
        />
      )}

      <p className="modal-desc" style={{ marginBottom: "20px" }}>
        * 클릭시 확대 이미지를 볼 수 있습니다.
      </p>

      <BaseButton text="확인" onClick={close} />
    </ModalBase>
  );
};

export default ModalPaperView;
