import cancel from "../../assets/cancel.png";

interface IProps {
  onClick: () => void;
  style?: React.CSSProperties;
}
const CloseCircleButton = (props: IProps) => {
  return (
    <span
      style={{
        cursor: "pointer",
        padding: "5px",
        width: "33px",
        height: "33px",
        ...(props.style ?? {}),
      }}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick();
      }}
    >
      <img src={cancel} style={{ width: "100%", height: "100%" }} />
    </span>
  );
};

export default CloseCircleButton;
