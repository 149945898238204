import { useRecoilState } from "recoil";
import { atom__postDetailModalInfo } from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useRef, useState } from "react";
import { TPost } from "../../lib/types/post.type";
import { api_postDetail } from "../../api/post";
import ModalBase from "./Base";
import {
  convertToPyeong,
  printCost,
  printFormattedDateKr,
} from "../../lib/util";
import iconArrowDouble from "../../assets/arrow-right-double.png";
import iconArrowLeft from "../../assets/arrow-left-background.png";
import iconArrowRight from "../../assets/arrow-right-background.png";
import iconMedalGold from "../../assets/medal_gold.png";
import iconMedalSilver from "../../assets/medal_silver.png";
import iconMedalBronze from "../../assets/medal_bronze.png";
import { USER_LEVEL_TO_LABEL } from "../../lib/types/user.type";
import postDefaultImage from "../../assets/post-default-480_260.png";

const PostInfoRow = ({ label, info }: { label: string; info: string }) => {
  return (
    <div className="flex-row-start-center" style={{ padding: "3px 0" }}>
      <p
        className="label-small regular"
        style={{
          width: "90px",
          color: "var(--border-gray)",
          textAlign: "left",
        }}
      >
        {label}
      </p>
      <p
        className="label-small regular"
        style={{
          flex: 1,
          textAlign: "left",
        }}
      >
        {info}
      </p>
    </div>
  );
};

const ModalPostDetail = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__postDetailModalInfo
  );

  const [detail, setDetail] = useState<(TPost & { badgeWorth: number }) | null>(
    null
  );
  const [headlineCost, setHeadlineCost] = useState("");
  const [badgeImg, setBadgeImg] = useState<string | null>(null);
  const [currentImgIdx, setCurrentImgIdx] = useState(0);

  const refCached = useRef<Record<string, TPost & { badgeWorth: number }>>({});

  useEffect(() => {
    if (!r__modalInfo) {
      setDetail(null);
      return;
    }

    const postId = r__modalInfo.postId;
    // 캐싱된거 있으면 가져가 쓰고
    if (refCached.current[postId]) {
      setDetail(refCached.current[postId]);
      return;
    }
    // 캐싱된거 없으면 fetch해서 쓰고
    _fetch(postId);
  }, [r__modalInfo]);

  useEffect(() => {
    if (!detail) {
      setHeadlineCost("");
      setBadgeImg(null);
      return;
    }

    // 헤드라인 (ex: 매매 7억 1,000)
    let hCost = detail.saleKind + " ";
    hCost += printCost(detail.costOnce);
    if (detail.costMonth)
      hCost += " " + printCost(detail.costMonth, { manwonLabel: "만원" });
    setHeadlineCost(hCost);

    // 사무소 배지
    if (detail.badgeWorth === 0) {
      setBadgeImg(null);
      return;
    }
    setBadgeImg(
      detail.badgeWorth === 50
        ? iconMedalGold
        : detail.badgeWorth === 30
        ? iconMedalSilver
        : iconMedalBronze
    );
  }, [detail]);

  const _fetch = (id: string) => {
    api_postDetail(id).then((res) => {
      if (!res) return;
      refCached.current[id] = res.post;
      setDetail(res.post);
    });
  };

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  if (!detail)
    return (
      <ModalBase
        visible={!!r__modalInfo}
        onClickOuter={close}
        innerStyle={{ width: "480px" }}
      ></ModalBase>
    );

  return (
    <ModalBase
      visible={!!r__modalInfo}
      onClickOuter={close}
      innerStyle={{ width: "480px", padding: "0", borderRadius: "20px" }}
    >
      <div
        style={{
          width: "480px",
          height: "260px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* 진짜 사진 */}
        <img
          src={
            detail.images[currentImgIdx]?.url480W ??
            detail.images[currentImgIdx]?.preparedUrl ??
            postDefaultImage
          }
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "relative",
          }}
        />
        {/* dim(어둡게) 처리할 레이어 */}
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "#00000033",
            top: 0,
          }}
        />
        {/* 사진 n/m 표시 */}
        {detail.images.length > 0 && (
          <span
            style={{
              position: "absolute",
              bottom: "8px",
              right: "8px",
              fontSize: "10px",
              fontWeight: "700",
              color: "#fff",
              textShadow: "2px 3px 4px #555",
            }}
          >
            {currentImgIdx + 1} / {detail.images.length}
          </span>
        )}
        {/* 이전사진/다음사진 버튼 */}
        {detail.images.length >= 2 && (
          <div
            className="flex-row-between-center"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              padding: "0 8px",
            }}
          >
            <img
              src={iconArrowLeft}
              style={{
                width: "36px",
                height: "36px",
                cursor: "pointer",
              }}
              onClick={() =>
                setCurrentImgIdx((prev) => {
                  if (prev === 0) return detail.images.length - 1;
                  return prev - 1;
                })
              }
            />
            <img
              src={iconArrowRight}
              style={{
                width: "36px",
                height: "36px",
                cursor: "pointer",
              }}
              onClick={() =>
                setCurrentImgIdx((prev) => {
                  if (prev === detail.images.length - 1) return 0;
                  return prev + 1;
                })
              }
            />
          </div>
        )}
      </div>

      <div style={{ padding: "20px" }}>
        {/* 가격, 아파트명 */}
        <div
          className="flex-row-between-center"
          style={{ marginBottom: "10px" }}
        >
          <h2>{headlineCost}</h2>
          <p
            className="label-small regular"
            style={{ color: "var(--border-gray)" }}
          >
            {detail.apart.kaptName}
          </p>
        </div>

        {/* 타입, 방/욕실수, 동, 층 */}
        <div className="flex-row-start-center" style={{ marginBottom: "36px" }}>
          <p className="label-small regular" style={{ width: "70px" }}>
            {detail.areaType}
          </p>
          <span
            style={{
              width: "2px",
              height: "30px",
              backgroundColor: "var(--border-gray)",
              margin: "0 3px",
            }}
          />
          <p className="label-small regular" style={{ width: "70px" }}>
            방{detail.cntRoom} / 욕실{detail.cntBath}
          </p>
          <span
            style={{
              width: "2px",
              height: "30px",
              backgroundColor: "var(--border-gray)",
              margin: "0 3px",
            }}
          />
          <p className="label-small regular" style={{ width: "70px" }}>
            {detail.dong}동
          </p>
          <span
            style={{
              width: "2px",
              height: "30px",
              backgroundColor: "var(--border-gray)",
              margin: "0 3px",
            }}
          />
          <p className="label-small regular" style={{ width: "70px" }}>
            {detail.floor}층/{detail.apart.kaptTopFloor}층
          </p>
        </div>

        {/* 중개사이름 및 배지 */}
        <div
          className="flex-row-center-center"
          style={{ marginBottom: "36px" }}
        >
          <img
            src={iconArrowDouble}
            style={{ width: "24px", height: "24px" }}
          />

          {badgeImg !== null && (
            <img src={badgeImg} style={{ width: "36px", height: "32px" }} />
          )}

          <p className="username" style={{ marginLeft: "14px" }}>
            {/* 소유주 이름 -> 또는 중개사 이름 -> 또는 중개보조인이 올렸다면 사무소 이름 */}
            {detail.ownerName || ""}

            {!!detail.creatorUser && detail.creatorUser.level >= 40
              ? `${detail.creatorUser.name} ${
                  USER_LEVEL_TO_LABEL[detail.creatorUser.level]
                }`
              : `${detail?.office?.name}`}
          </p>
        </div>

        {/* 소개글 */}
        <p
          className="label-small"
          style={{ textAlign: "left", marginBottom: "6px" }}
        >
          {detail.introduceTitle}
        </p>
        <p
          className="label-small"
          style={{
            textAlign: "left",
            color: "var(--border-gray)",
            marginBottom: "36px",
          }}
        >
          {detail.introduceContent}
        </p>

        {/* 기타 정보 */}
        <p
          className="label-small"
          style={{ textAlign: "left", marginBottom: "6px" }}
        >
          아파트정보
        </p>

        <PostInfoRow label="타입" info={detail.areaType} />
        <PostInfoRow
          label="면적(평)"
          info={`전용 ${convertToPyeong(
            detail.exclusiveArea
          )}평 / 공용 ${convertToPyeong(detail.commonArea)}평`}
        />
        <PostInfoRow
          label="평균 관리비"
          info={printCost(detail.manageFeeAvg, { manwonLabel: "만원" })}
        />
        <PostInfoRow label="관리비 항목" info={detail.manageFeeName} />
        <PostInfoRow
          label="방 수 / 욕실 수"
          info={`${detail.cntRoom}개 / ${detail.cntBath}개`}
        />
        <PostInfoRow label="방향" info={detail.window} />
        <PostInfoRow
          label="입주가능일"
          info={
            detail.enterableDate
              ? `협의 (${printFormattedDateKr(
                  new Date(detail.enterableDate)
                )}부터)`
              : "협의"
          }
        />
        <PostInfoRow label="융자" info={detail.mortgage} />
        <PostInfoRow
          label="보증보험"
          info={detail.canInsurance ? "가능" : "불가능"}
        />
      </div>
    </ModalBase>
  );
};

export default ModalPostDetail;
