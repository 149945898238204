import { useRecoilState, useSetRecoilState } from "recoil";
import ModalBase from "./Base";
import {
  atom__newsEditModalInfo,
  atom__successModalInfo,
} from "../../lib/recoil/modal.atom";
import { useCallback, useEffect, useState } from "react";
import CloseCircleButton from "../Button/CloseCircle";
import BaseButton from "../Button/Base";
import Select from "../Select";
import { api_newsDetail, api_newsUpdate } from "../../api/site";

const ModalNewsEdit = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__newsEditModalInfo
  );
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);

  const [subject, setSubject] = useState("");
  const [url, setUrl] = useState("");
  const [source, setSource] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("전국");

  const [canNext, setCanNext] = useState(false);

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);

  const _fetch = () => {
    if (!r__modalInfo) return;

    api_newsDetail(r__modalInfo.newsId).then((res) => {
      if (!res) return;
      setSubject(res.news.subject);
      setSource(res.news.source);
      setUrl(res.news.url);
      setSelectedRegion(res.news.region);
    });
  };

  // 닫혔을 때 인풋 초기화
  useEffect(() => {
    if (r__modalInfo) {
      _fetch();
      return;
    }

    setSubject("");
    setUrl("");
    setSource("");
    setSelectedRegion("전국");
  }, [r__modalInfo]);

  useEffect(() => {
    setCanNext(!!subject.length && !!url.length && !!source.length);
  }, [subject, url, source]);

  const edit = async () => {
    if (!r__modalInfo) return;

    const res = await api_newsUpdate(r__modalInfo.newsId, {
      subject,
      url,
      source,
      region: selectedRegion,
    });
    if (!res) return;

    r__modalInfo?.onEdit();
    close();

    r__setSuccessModalInfo({
      desc: ["수정되었습니다."],
      onClickBtn: () => r__setSuccessModalInfo(null),
    });
  };

  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div className="flex-row-between-center" style={{ marginBottom: "40px" }}>
        <h2>뉴스 수정</h2>
        <CloseCircleButton onClick={close} />
      </div>

      <label htmlFor="subject">제목</label>
      <input
        id="subject"
        type="text"
        placeholder=""
        maxLength={50}
        style={{ marginBottom: "20px" }}
        value={subject}
        onChange={(e) => {
          setSubject(e.target.value);
        }}
      />

      <label htmlFor="url">URL</label>
      <input
        id="url"
        type="text"
        placeholder=""
        maxLength={300}
        style={{ marginBottom: "20px" }}
        value={url}
        onChange={(e) => {
          setUrl(e.target.value);
        }}
      />

      <label htmlFor="source">출처</label>
      <input
        id="source"
        type="text"
        placeholder=""
        maxLength={30}
        style={{ marginBottom: "20px" }}
        value={source}
        onChange={(e) => {
          setSource(e.target.value);
        }}
      />

      <label htmlFor="region">지역</label>
      <div className="flex-row-start-center" style={{ marginBottom: "30px" }}>
        <Select
          data={[
            { label: "전국", value: "전국" },
            { label: "수원", value: "수원" },
          ]}
          value={selectedRegion}
          onChange={(v) => setSelectedRegion(v)}
        />
      </div>

      <BaseButton text="수정" onClick={edit} disabled={!canNext} />
    </ModalBase>
  );
};

export default ModalNewsEdit;
