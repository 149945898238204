import { TListQuery } from "../lib/types/common.type";
import {
  TOffice,
  TOfficeChange,
  TOfficeField,
  TOfficeSearchResultItem,
} from "../lib/types/office.type";
import { TUser } from "../lib/types/user.type";
import axios from "./axios";

export const api_officeCreate = async (payload: TOfficeField) => {
  const res = await axios.post("/office", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_officeUpdate = async (id: string, payload: TOfficeField) => {
  const res = await axios.put("/office/" + id, payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_officeSearch = async (keyword: string) => {
  const res = await axios.get("/office/search?keyword=" + keyword);
  return res.data?.data as { list: TOfficeSearchResultItem[] };
};

export const api_officeList = async (query: TListQuery) => {
  const res = await axios.get("/office", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {
    list: (TOffice & { badge: { worth: number; endDate: string } | null })[];
    totalCount?: number;
  };
};
export const api_officeDetail = async (id: string) => {
  const res = await axios.get("/office/" + id);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { office: TOffice };
};

export const api_officeChangeList = async (query: TListQuery) => {
  const res = await axios.get("/office/change", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TOfficeChange[]; totalCount?: number };
};
export const api_officeChangeAccept = async (id: string) => {
  const res = await axios.post(`/office/change/${id}/accept`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};
export const api_officeChangeReject = async (id: string) => {
  const res = await axios.post(`/office/change/${id}/reject`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};
