import { TListQuery } from "../lib/types/common.type";
import { TUserMembership } from "../lib/types/membership.type";
import axios from "./axios";

export const api_membershipPendingList = async (query: TListQuery) => {
  const res = await axios.get("/user-membership/pending", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TUserMembership[]; totalCount?: number };
};

export const api_membershipPendingDetail = async (id: string) => {
  const res = await axios.get("/user-membership/" + id);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { userMembership: TUserMembership };
};

export const api_membershipAccept = async (
  id: string,
  payload: { startDate: string }
) => {
  const res = await axios.post(`/user-membership/${id}/accept`, payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_membershipRefund = async (id: string) => {
  const res = await axios.post(`/user-membership/${id}/refund`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};

export const api_membershipSms = async (id: string) => {
  const res = await axios.post(`/user-membership/${id}/sms`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as {};
};
