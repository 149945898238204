import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { TSNBMenuItem } from "./menu";

type Props = {
  item: TSNBMenuItem;
  isActive: boolean;
};

function SideBarItem({ item, isActive }: Props) {
  return (
    <li className="snb-menu">
      <Link
        className="text-lg w-full block py-4 pl-10 text-[#323232]"
        to={item.path}
      >
        <span>{item.name}</span>
      </Link>
    </li>
  );
}

export default SideBarItem;
