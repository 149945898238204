import { useEffect, useMemo, useRef, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Th from "../../components/Table/Th";
import { printFormattedDate } from "../../lib/util";
import SmallButton from "../../components/Button/Small";
import { useSetRecoilState } from "recoil";
import {
  atom__confirmModalInfo,
  atom__paperViewModalInfo,
  atom__searchOfficeModalInfo,
  atom__successModalInfo,
  atom__userRejectModalInfo,
} from "../../lib/recoil/modal.atom";
import Select from "../../components/Select";
import {
  api_connectOffice,
  api_userAccept,
  api_userList,
} from "../../api/user";
import { TUser, USER_LEVEL_TO_LABEL } from "../../lib/types/user.type";
import Pagination from "../../components/Table/Pagination";
import { TOfficeSearchResultItem } from "../../lib/types/office.type";

const UserApprovalPage = () => {
  const r__setSuccessModalInfo = useSetRecoilState(atom__successModalInfo);
  const r__setConfirmModalInfo = useSetRecoilState(atom__confirmModalInfo);
  const r__setPaperViewModalInfo = useSetRecoilState(atom__paperViewModalInfo);
  const r__setUserRejectModalInfo = useSetRecoilState(
    atom__userRejectModalInfo
  );
  const r__setSearchOfficeModalInfo = useSetRecoilState(
    atom__searchOfficeModalInfo
  );

  const [filterKind, setFilterKind] = useState("none"); // 'none' | '개업공인중개사' | '소속공인중개사' | '중개보조원'

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState<TUser[]>([]);

  const limit = useMemo(() => 10, []);
  const refSearchAttribute = useRef("");
  const refSearchKeyword = useRef("");

  useEffect(() => {
    _fetch({
      page: 1,
      needTotalCount: "y",
    });
  }, [filterKind]);

  const approve = async (userId: string) => {
    const res = await api_userAccept({ userId });
    if (!res) return;

    r__setConfirmModalInfo(null);
    r__setSuccessModalInfo({
      desc: ["가입 승인되었습니다"],
    });

    setCurrentPage(1);
    _fetch({ page: 1, needTotalCount: "y" });
  };

  const _fetch = ({
    page,
    needTotalCount,
  }: {
    page: number;
    needTotalCount?: string;
    userKind?: string;
  }) => {
    api_userList({
      from: limit * (page - 1),
      limit,
      searchField: refSearchAttribute.current || undefined,
      searchKeyword: refSearchKeyword.current || undefined,
      needTotalCount,
      userKind: filterKind !== "none" ? filterKind : undefined,
      status: ["pending"],
    }).then((res) => {
      if (!res) return;
      setList(res.list);

      if (needTotalCount === "y") {
        setTotalCount(res.totalCount ?? 0);
      }
    });
  };

  const connectOffice = (userId: string, selected: TOfficeSearchResultItem) => {
    api_connectOffice({ userId, officeId: selected.id }).then((res) => {
      if (!res) return;
      r__setSuccessModalInfo({
        desc: ["부동산이 성공적으로 연결되었습니다."],
      });

      _fetch({ page: currentPage });
    });
  };

  return (
    <div className="table-container">
      <div className="flex-row-between-center" style={{ marginBottom: "20px" }}>
        <div className="flex-row-start-center">
          <h2 style={{ marginRight: "30px" }}>가입 요청</h2>
          <SearchInput
            onClickSearch={(keyword, attribute) => {
              refSearchAttribute.current = attribute;
              refSearchKeyword.current = keyword.trim();
              setCurrentPage(1);
              _fetch({ page: 1, needTotalCount: "y" });
            }}
            attributes={["아이디", "이름"]}
          />
        </div>
        <Select
          data={[
            { label: "유형", value: "none" },
            { label: "개업공인중개사", value: "개업공인중개사" },
            { label: "소속공인중개사", value: "소속공인중개사" },
            { label: "중개보조원", value: "중개보조원" },
          ]}
          onChange={(v) => {
            console.log("changed: ", v);
            setFilterKind(v);
          }}
          value={filterKind}
        />
      </div>

      <div style={{ flex: 1 }}>
        <table>
          <thead>
            <Th text="요청일" sort="none" width={150} />
            <Th text="아이디" sort="none" width={200} />
            <Th text="이름" sort="none" width={100} />
            <Th text="전화번호" sort="none" />
            <Th text="유형" sort="none" width={180} />
            <Th text="사무소" sort="none" width={200} />
            <Th text="대표번호" sort="none" width={150} />
            <Th text="관리" sort="none" />
          </thead>
          <tbody>
            {list.map((user) => {
              return (
                <tr key={user.id}>
                  <td className="small">
                    {printFormattedDate(new Date(user.createdAt))}
                  </td>
                  <td>{user.uid}</td>
                  <td>{user.name}</td>
                  <td>{user.phone}</td>
                  <td>{USER_LEVEL_TO_LABEL[user.level] || "-"}</td>
                  {/* <td>{"행복부동산"}</td> */}
                  <td>
                    {user.office?.name || (
                      <SmallButton
                        text="연결"
                        color="brown"
                        width={100}
                        onClick={() => {
                          r__setSearchOfficeModalInfo({
                            onSelectOffice: (selected) => {
                              connectOffice(user.id, selected);
                            },
                          });
                        }}
                      />
                    )}
                  </td>
                  <td>{user.officeHp || "-"}</td>
                  <td>
                    <div
                      className="flex-row-center-center"
                      style={{ gap: "10px" }}
                    >
                      <SmallButton
                        text="문서 확인"
                        color="beige"
                        width={100}
                        onClick={() => {
                          r__setPaperViewModalInfo({
                            paperUrl1: user.imageUrlBusiness,
                            paperUrl2: user.imageUrlOffice,
                            paperUrl3: user.imageUrlCert,
                          });
                        }}
                      />

                      <SmallButton
                        text="승인"
                        color="yellow-dark"
                        width={64}
                        disabled={!user.office}
                        onClick={() => {
                          r__setConfirmModalInfo({
                            title: "가입 승인",
                            desc: ["가입 승인하시겠습니까?"],
                            onClickBtnLeft: () => r__setConfirmModalInfo(null),
                            onClickBtnRight: () => {
                              approve(user.id);
                            },
                            theme: "info",
                          });
                        }}
                      />

                      <SmallButton
                        text="반려"
                        color="tomato"
                        width={64}
                        onClick={() => {
                          r__setUserRejectModalInfo({
                            userId: user.id,
                            onReject: () => {
                              setCurrentPage(1);
                              _fetch({ page: 1, needTotalCount: "y" });
                            },
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        pagePerGroup={10}
        itemPerPage={10}
        currentPage={currentPage}
        totalItem={totalCount}
        onClickPage={(page) => {
          setCurrentPage(page);
          _fetch({ page });
        }}
      />
    </div>
  );
};

export default UserApprovalPage;
