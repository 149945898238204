import axios from "./axios";

export const api_login = async (payload: { uid: string; pw: string }) => {
  const res = await axios.post("/admin/login", payload);
  if (!res) return;
  if (!res.data.success) return alert(res.data.err.msg);

  return res.data.data as {
    accessToken: string;
    admin: { id: string; name: string; level: number };
  };
};

export const api_logout = async () => {
  const res = await axios.post("/admin/logout", null);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_me = async () => {
  const res = await axios.get("/admin/me");
  if (!res) return;

  return res.data.data;
};
