import { PropsWithChildren, useCallback } from "react";

interface IProps extends PropsWithChildren {
  visible: boolean;
  innerStyle?: React.CSSProperties;
  onClickOuter: () => void;
}
const ModalBase = (props: IProps) => {
  const close = useCallback(() => {
    props.onClickOuter();
  }, [props]);

  return (
    <div className={`modal ${props.visible ? "modal-visible" : ""}`}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        onClick={close}
      />
      <div className={`modal-base-inner`} style={props.innerStyle}>
        {props.children}
      </div>
    </div>
  );
};

export default ModalBase;
