import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { LoginPage } from "./pages/Login";
import Layout from "./components/Layout";
import AdminAccountPage from "./pages/account/Admin";
import SalesAccountPage from "./pages/account/Sales";
import UserApprovalPage from "./pages/user/Approval";
import UserListPage from "./pages/user/List";
import RootPage from "./pages/Root";
import NotFountPage from "./pages/NotFound";
import UserOfficeChangePage from "./pages/user/OfficeChange";
import ComplaintPage from "./pages/user/Complaint";
import BadgePage from "./pages/user/Badge";
import MembershipPage from "./pages/user/Membership";
import OfficeListPage from "./pages/office/OfficeList";
import DepositListPage from "./pages/deposit/DepositList";
import BannerListPage from "./pages/site/Banner";
import NewsListPage from "./pages/site/NewsList";
import NewsRegisterPage from "./pages/site/NewsRegister";
import NoticeListPage from "./pages/site/Notice";
import PostImagePage from "./pages/post/Image";
import PostPendingPage from "./pages/post/PendingList";
import PostListPage from "./pages/post/AcceptList";
import LogAdminListPage from "./pages/site/Log";

function App() {
  return (
    <Routes>
      <Route path="/" element={<RootPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route element={<Layout />}>
        <Route path="/account/admin" element={<AdminAccountPage />} />
        <Route path="/account/sales" element={<SalesAccountPage />} />

        <Route path="/user/approval" element={<UserApprovalPage />} />
        <Route path="/user/list" element={<UserListPage />} />
        <Route path="/user/office-change" element={<UserOfficeChangePage />} />
        <Route path="/user/complaint" element={<ComplaintPage />} />
        <Route path="/user/badge" element={<BadgePage />} />
        <Route path="/user/membership" element={<MembershipPage />} />

        <Route path="/office/list" element={<OfficeListPage />} />

        <Route path="/post/list" element={<PostListPage />} />
        <Route path="/post/pending" element={<PostPendingPage />} />
        <Route path="/post/image" element={<PostImagePage />} />

        <Route path="/deposit/list" element={<DepositListPage />} />

        <Route path="/site/banner" element={<BannerListPage />} />
        <Route path="/site/news" element={<NewsListPage />} />
        <Route path="/site/news/register" element={<NewsRegisterPage />} />
        <Route path="/site/notice" element={<NoticeListPage />} />

        <Route path="/log" element={<LogAdminListPage />} />
      </Route>

      <Route path="/*" element={<NotFountPage />} />
    </Routes>
  );
}

export default App;
