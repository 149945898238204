import { TListQuery } from "../lib/types/common.type";
import { TUser } from "../lib/types/user.type";
import axios from "./axios";

export const api_userList = async (
  query: TListQuery & {
    userKind?: string;
    withMembership?: "y" | "n";
    status?: ("accept" | "pending" | "reject")[];
  }
) => {
  const res = await axios.get("/user", { params: query });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data as { list: TUser[]; totalCount?: number };
};

export const api_connectOffice = async (payload: {
  officeId: string;
  userId: string;
}) => {
  const res = await axios.post(`/user/${payload.userId}/connect-office`, {
    officeId: payload.officeId,
  });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};

export const api_userReject = async (payload: {
  reason: string;
  userId: string;
}) => {
  const res = await axios.post(`/user/${payload.userId}/reject`, {
    reason: payload.reason,
  });
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};
export const api_userAccept = async (payload: { userId: string }) => {
  const res = await axios.post(`/user/${payload.userId}/accept`);
  if (!res) return;
  if (!res.data.success) return alert(res.data?.err?.msg);

  return res.data.data;
};
