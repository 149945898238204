import { useCallback } from "react";
import ModalBase from "./Base";
import { useRecoilState } from "recoil";
import { atom__postPhoneModalInfo } from "../../lib/recoil/modal.atom";
import check from "../../assets/check.png";
import BaseButton from "../Button/Base";

const ModalPostPhone = () => {
  const [r__modalInfo, r__setModalInfo] = useRecoilState(
    atom__postPhoneModalInfo
  );

  const close = useCallback(() => {
    r__setModalInfo(null);
  }, []);
  return (
    <ModalBase visible={!!r__modalInfo} onClickOuter={close}>
      <div>
        <h2 style={{ marginBottom: "20px" }}>등록 전화번호</h2>

        {!!r__modalInfo?.creatorPhone && (
          <>
            <p
              className="label-small"
              style={{ marginBottom: "8px", textAlign: "left" }}
            >
              등록자
            </p>
            <p
              className="label-small"
              style={{
                fontWeight: 400,
                marginBottom: "24px",
                textAlign: "left",
              }}
            >
              {r__modalInfo.creatorPhone}
            </p>
          </>
        )}

        {!!r__modalInfo?.ownerPhone && (
          <>
            <p
              className="label-small"
              style={{ marginBottom: "8px", textAlign: "left" }}
            >
              소유주
            </p>
            <p
              className="label-small"
              style={{
                fontWeight: 400,
                marginBottom: "24px",
                textAlign: "left",
              }}
            >
              {r__modalInfo.ownerPhone}
            </p>
          </>
        )}

        {!!r__modalInfo?.customerPhone && (
          <>
            <p
              className="label-small"
              style={{ marginBottom: "8px", textAlign: "left" }}
            >
              세입자
            </p>
            <p
              className="label-small"
              style={{
                fontWeight: 400,
                marginBottom: "24px",
                textAlign: "left",
              }}
            >
              {r__modalInfo.customerPhone}
            </p>
          </>
        )}

        <BaseButton onClick={close} text={"확인"} style={{ width: "344px" }} />
      </div>
    </ModalBase>
  );
};

export default ModalPostPhone;
